import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue'),
      meta: { requiresAuth: true },
      children: [{
            path: '/',
            name: 'Empresa',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/empresa/Index.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/curso-empresa/:id',
            name: 'Cursos por empresa',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/empresa/CursoEmpresa.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/archivo-empresa/:idEmpresa/:idCurso',
            name: 'Empresa archivo curso',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/empresa/ArchivoCursoEmpresa.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/curso',
            name: 'curso',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/curso/Curso.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: '/usuarios',
            name: 'usuarios',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/usuario/Index.vue'),
            meta: { requiresAuth: true },
          }]
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../views/HomeView.vue'),
    },
  ]
})
router.beforeEach(async (to, from, next) => {
  let login = (localStorage.getItem('login') == 'true') ? true : false;
  let token = (localStorage.getItem('token')) ? true : false;
  let autenticado = login && token
  if (to.meta?.requiresAuth && !autenticado) {
    next({
      path: '/login',
      replace: true
    })
  } else if (to.name === 'Login' && autenticado) {
    next({
      path: '/',
      replace: true
    })
  } else {
    next()
  }
})

export default router
