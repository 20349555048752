const mutations = {
  authData (state) {
    if (state.login) {
      return
    }

    if (localStorage.getItem('token') || localStorage.getItem('login')) {
      state.token = localStorage.getItem('token') || ''
      state.login = !!localStorage.getItem('login')
    }
  },
  getPerfil(state, payload) {
    state.datosPerfil = payload;
  },
  
  salir (state) {
    state.datosPerfil = {}
    state.login = false
    state.token = ''
  }

}

export default mutations
