import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import installVuetify from './plugins/vuetify/vuetify'
import axios from './plugins/axios/axios'

const vuetify = installVuetify()
const app = createApp(App)
app.config.globalProperties.$axios = axios

app.use(store)
app.use(router)
app.use(vuetify)
app.mount('#app')
