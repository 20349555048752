import axios from 'axios';

const { VUE_APP_API } = process.env;

const axiosInstance = axios.create({
  baseURL: VUE_APP_API,
  timeout: 5000
});

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (config.url === '/login') {
    if (config.headers.common && config.headers.common.Authorization) {
      delete config.headers.common.Authorization;
    }
  }
  return config;
});

export default axiosInstance;
