import axios from '@/plugins/axios/axios';

const actions = {
  async getPerfil (context) {
    const result = await axios.get('/usuario/perfil')
    context.commit('getPerfil',result.data)
  }

}

export default actions
