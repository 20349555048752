const getters = {
  login (state) {
    return state.login
  },
  token (state) {
    return state.token
  },
  gettersPerfil (state) {
    return state.datosPerfil
  }
}

export default getters
